@media(min-width: 1200px){
  .bd-sidebar1{
    -webkit-box-flex: 0;
    -ms-flex: 0 1 320px;
    flex: 0 1 320px;
  }
}
@media (min-width: 768px){
  .bd-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 999;
    height: calc(100vh - 4rem);
    overflow-y:auto;
  }
  .bd-sidebar {
    border-right: 1px solid rgba(0,0,0,.1);
  }
}
@media (min-width:576px){
  .media_fixed{
    max-height:30vh;
  }
}
@media (max-width: 576px) { 
  .media_fixed{
    max-height: 20vh;
  }
}

@media (min-width: 480px) {
  .hidemobie{
    display: none;
  }
}
.bd-sidebar {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.bd-sidebar::-webkit-scrollbar{
  width: 8px;
  background-color: #F5F5F5;
}
.bd-sidebar::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
.bd-sidebar::-webkit-scrollbar-thumb{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #CCC;
}
.nav-link{
  cursor:pointer;
}

.btn-background{
  height: 12vh;
  background-size: auto 100%;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000,0 -1px #000;
}

.btn-green{
  color:#fff;
  background-color:#4BA747;
  border-color: #4BA747;
}

.btn-blue{
  color:#fff;
  background-color:#0C78BD;
  border-color: #0C78BD;
}

.btn-yellow{
  color:rgb(55, 110, 53);
  background-color:#f5d211;
  border-color: #f5d211;
}

.rowspecial{
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.progress {
  background-color: #afadad !important;
 
}